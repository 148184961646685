import * as React from "react"
import HeaderBlock, {
  Caption,
} from "../components/investorsAndFounders/headerBlock"
import OurValues from "../components/investorsAndFounders/ourValues"
import Layout from "../components/layout"
import FounderQuotes from "../components/investorsAndFounders/founderQuotes"
import Feedback from "../components/investorsAndFounders/for-investors/feedback"
import GlobalTeam from "../components/globalTeam"
import Portfolio from "../components/portfolio"
import { graphql } from "gatsby"
import GridLines from "../components/gridLines"
import { TextAccent } from "../components/styles"
import { useMediaQuery } from "@mui/material"
import Seo from "../components/seo"
import JobsBlock from "../components/investorsAndFounders/jobsBlock"

const pointsData = [
  {
    label: "12 funds",
    body:
      "With more than twelve funds under our belt, successful exits and more than $250M in deployed " +
      "capital, we have cemented a broad experience in venture capital investments reinforced by opportunistic and " +
      "forward-looking vision.",
  },
  {
    label: "Early-stages",
    body:
      "Being on the frontier, we are among the first to identify trends and reveal market tendencies which allows " +
      "us to be engaged in the conversation with extraordinary founders as we are focused on early-stage " +
      "companies (Seed/A/B).",
  },
  {
    label: "Smart allocation",
    body:
     "The self imposed 10% rule emphasizes on the importance of diversity and discipline needed to make " +
      "sure our investments perform well."
  },
]

const CaptionMessage = () => {
  const isMobile = useMediaQuery("(max-width: 576px)")

  return (
    <Caption>
      {isMobile ? (
        <>
          We are on the mission <br />
          <TextAccent> to propel future-shaping tech startups </TextAccent>
          <br />
          toward extraordinary growth, <br />
          and maximize returns <br />
          for all involved.
        </>
      ) : (
        <>
          We are on the mission{" "}
          <TextAccent> to propel future-shaping tech startups </TextAccent>{" "}
          <br />
          toward extraordinary growth, and maximize returns <br />
          for all involved.
        </>
      )}
    </Caption>
  )
}

const ForInvestors = ({ data }) => {
  return (
    <Layout headerDark={false}>
      <Seo
        title="Investors"
        description={`We are on the mission to propel future-shaping tech startups toward extraordinary growth, and maximize returns for all involved.`}
      />
      <HeaderBlock
        title={"The Net Present Value of Innovation"}
        points={pointsData}
      >
        <CaptionMessage />
      </HeaderBlock>
      <GridLines
        styles={{
          height: 30,
          heightMob: 30,
          marginBottom: 0,
          marginBottomMob: 0,
          color: "#F8F8F8",
        }}
      />
      <OurValues />
      <JobsBlock />
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginBottom: 20,
          marginBottomMob: 25,
          color: "#F8F8F8",
        }}
      />
      <GlobalTeam data={data.strapiOurTeamOrder} />
      <GridLines
        styles={{
          height: 87,
          heightMob: 40,
          marginTop: 0,
          marginTopMob: 0,
          marginBottom: 21,
          marginBottomMob: 47,
          color: "#F8F8F8",
        }}
      />
      <Portfolio data={data} text="every company" />
      <GridLines
        styles={{
          height: 87,
          heightMob: 30,
          marginTop: 36,
          marginTopMob: 25,
          color: "#F8F8F8",
        }}
      />
      <FounderQuotes data={data.allStrapiFoundersQuote} />
      <Feedback />
    </Layout>
  )
}

export default ForInvestors

export const query = graphql`
  query ForInvestorsPageQuery {
    strapiOurTeamOrder {
      order {
        member_1 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_2 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_3 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_4 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        member_5 {
          position
          name
          photo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    allStrapiFoundersQuote(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          rank
          position
          name
          quote
          image_position
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
              publicURL
            }
          }
        }
      }
    }
    allFile {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 100)
        }
      }
    }
    allStrapiCompany(sort: { fields: rank, order: ASC }) {
      edges {
        node {
          strapi_id
          name
          color
          description
          year_founded
          location
          stage
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
              publicURL
            }
          }
          preview_images {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  }
`
